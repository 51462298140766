import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  testID?: string;
  translatingOptions?: Record<string, unknown>;
  classNames?: string;
  children?: ReactNode;
  style?: React.CSSProperties | undefined;
};

export const SText: React.FC<Props> = ({
  children = null,
  label,
  classNames,
  translatingOptions,
  style,
  testID = 'SText',
}) => {
  const { t } = useTranslation();

  return (
    <span
      data-testid={testID}
      className={`${classNames}`}
      dangerouslySetInnerHTML={{ __html: t(label, translatingOptions) }}
      style={style}
    >
      {children}
    </span>
  );
};
