import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOGOUT_TRIGGERS } from 'src/config';
import { UNAUTHORIZED, UNKNOWN_ERROR } from 'src/consts';
import { logout } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers/auth';
import { AnyObject } from 'src/types';
import { useToken } from '../useToken';

type FetcherOption = {
  requiredToken?: boolean;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: AnyObject;
};

export const useFetcher = <T,>(initialValue: T) => {
  const token = useToken();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<T>(initialValue);

  const dispatch = useDispatch();
  const resetData = useCallback(() => {
    setData(initialValue);
  }, [setData]);

  const fetcher = useCallback(
    async (endPoint: string, { requiredToken = true, method = 'POST', body }: FetcherOption) => {
      setError(null);
      setIsLoading(true);

      try {
        if (!token && requiredToken) {
          throw Error(UNAUTHORIZED);
        }

        const response = await fetch(endPoint, {
          method,
          headers: appHeaders(requiredToken ? (token as string) : ''),
          body: JSON.stringify(body),
        });

        const json = await response.json();
        checkApiError(json);
        setData(json);
      } catch (error) {
        if (error) {
          if (LOGOUT_TRIGGERS.includes((error as Error).message)) {
            dispatch(logout());
          }
        }
        console.error('Error Fetching resources ', { endPoint, body, error });
        setError((error as Error) ?? new Error(UNKNOWN_ERROR));
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setError, token, checkApiError, setData, appHeaders],
  );

  return {
    fetcher,
    isLoading,
    error,
    data,
    resetData,
  };
};
