import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { isAuthenticatedSelector } from '../auth';
type Props = {
  testID?: string;
};

export const PageNotFound: React.FC<Props> = ({ testID = 'PageNotFound' }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const goToHome = useCallback(() => {
    history.replace('/');
  }, [history]);

  return (
    <div
      className="flex items-center justify-center w-screen h-screen flex-col"
      style={{
        background: "url('/bg-track.png') center no-repeat",
      }}
    >
      <div>
        <h2 className="font-bold  text-3xl md:text-5xl  text-secondary">{t('page-not-found')}</h2>
      </div>
      <div className="my-4 block text-center ">
        <div className="flex flex-row items-center md:w-1/2 m-auto justify-center">
          <div className="ml-4">
            <img src="/L404.svg" className="block" height={100} width={300} />
          </div>
          <div className="font-bold text-xl md:text-2xl  text-primary text-left ml-4">{t('page-not-found-text')}</div>
        </div>

        {isAuthenticated && (
          <div className="mt-4">
            <Button label="home-page" extraClasses="py-2 px-8 text-xl" onClick={goToHome} />
          </div>
        )}
      </div>
    </div>
  );
};
