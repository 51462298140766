import { Action, Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers';
import { RootState } from 'src/store';
import { StartDeleteEntryAction, START_DELETE_ENTRY } from '../board.types';
import { deleteEntryOnEntriesTable, removeDeletingEntry } from '../boardSlice';

export const deleteEntryBoardMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type, id: entryId } = action as StartDeleteEntryAction;

  if (type !== START_DELETE_ENTRY) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  try {
    const response = await fetch(EndPoints.deleteEntry, {
      method: 'POST',
      headers: appHeaders(token),
      body: JSON.stringify({
        entryId,
      }),
    });

    const json = await response.json();
    checkApiError(json);
    api.dispatch(deleteEntryOnEntriesTable({ id: entryId }));
  } catch (e) {
    console.error('Error while delete entry ', e);
  } finally {
    api.dispatch(removeDeletingEntry({ id: entryId }));
  }
};
