import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SCRoutes } from 'src/config';
import { SiteHeader } from 'src/containers/SiteHeader';
import { startLoadAppSettings } from 'src/features/appSettings';
import { LoginScreen } from 'src/features/auth/LoginScreen';
import { SignUpScreen } from 'src/features/auth/SignupScreen/SignUpScreen';
import { isAuthenticatedSelector } from 'src/features/auth/store';
import { PageNotFound } from 'src/features/PageNotFound';
import { ResetPassword } from 'src/features/ResetPassword';
import { SoldyBoard } from 'src/features/SoldyBoard';
import { AuthenticatedAppWrapper } from './AuthenticatdAppWrapper';

export const MainApp = (): JSX.Element => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(startLoadAppSettings());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const currentPAge = history.location.pathname ?? '/';
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UA ?? 'UA-217132864-2');
    ReactGA.pageview(`${currentPAge}${history.location.search}`);

    return history.listen((location) => {
      ReactGA.pageview(`${location.pathname}${location.search}`);
    });
  }, [history]);

  if (!isAuthenticated) {
    return (
      <div>
        <SiteHeader />

        <Switch>
          <Route component={ResetPassword} path={SCRoutes.resetPassword} exact={true} />
          <Route component={SignUpScreen} path={SCRoutes.signup} exact={true} />
          <Route component={LoginScreen} path="/" exact={true} />
          <Route path="*" component={PageNotFound} exact={true} />
        </Switch>
      </div>
    );
  }

  return (
    <AuthenticatedAppWrapper>
      <div>
        <SiteHeader />
        <Switch>
          <Route component={ResetPassword} path={SCRoutes.resetPassword} exact={true} />
          <Route component={SoldyBoard} path="/" exact={true} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </AuthenticatedAppWrapper>
  );
};
