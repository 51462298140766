import React from 'react';

type Props = {
  testID?: string;
};

export const Footer: React.FC<Props> = ({ testID = 'Footer' }) => {
  return (
    <div
      data-testid={testID}
      className="py-3 px-2 flex justify-center bg-secondary fixed z-10 bottom-0 right-0 w-full hidden md:block"
    >
      <div className="text-center ">
        <span className="text-white text-xl font-semibold">YAN CLIC</span>
        <span className="text-white text-xl ml-1 border-white border-l pl-1">Tél:+212 5 28 83 30 43</span>
        <span className="text-white text-md ml-1 border-white border-l pl-1">ICE: 002969929000071</span>
        <span className="text-white text-md ml-1 border-white border-l pl-1">
          ADRESSE : N 13 AVENUE FARHAT HACHAD ZI TASSILA DCHEIRA INEZGANE
        </span>
      </div>
    </div>
  );
};
