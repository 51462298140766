import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactChild;
};
export const InitialProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.classList.toggle('ar', i18n.language === 'ar');
  }, [i18n.language]);
  return <>{children}</>;
};
