import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateUserErrorPayload, UpdateUserInfoAction, UpdateUserLoadingPayload, UserState } from '../user.types';

const initialState: UserState = {
  isLoading: false,
  error: null,
  info: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UpdateUserInfoAction>) => {
      state.info = action.payload.info;
    },
    setUserLoading: (state, action: PayloadAction<UpdateUserLoadingPayload>) => {
      state.isLoading = action.payload.isLoading;
    },
    setUserError: (state, action: PayloadAction<UpdateUserErrorPayload>) => {
      state.error = action.payload.error;
    },
  },
});

export const { updateUserInfo, setUserLoading, setUserError } = userSlice.actions;
export const useSlicerReducer = userSlice.reducer;
