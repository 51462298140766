import { RootState } from 'src/store';
import { capabilities } from '../user.types';

export const userSelector = (state: RootState) => state.user;

export const isClientEditorSelector = (state: RootState): boolean => {
  const { user } = state;
  if (!user.info) return false;
  return user?.info?.capabilities?.includes(capabilities.clientEditor);
};

export const isClientViewerSelector = (state: RootState): boolean => {
  const { user } = state;
  if (!user.info) return false;
  return user?.info?.capabilities?.includes(capabilities.clientViewer);
};

export const isProviderViewerSelector = (state: RootState): boolean => {
  const { user } = state;
  if (!user.info) return false;
  return user?.info?.capabilities?.includes(capabilities.providerViewer);
};

export const isProviderEditorSelector = (state: RootState): boolean => {
  const { user } = state;
  if (!user.info) return false;
  return user?.info?.capabilities?.includes(capabilities.providerEditor);
};

export const isSuperAdminUserSelector = (state: RootState) => {
  const { user } = state;
  return user?.info?.role === 'SUPPERADMIN';
};

export const isAdminUserSelector = (state: RootState) => {
  const { user } = state;
  return user?.info?.role === 'ADMIN';
};
