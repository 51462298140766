import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { AppSettings } from 'src/types';

export const appConfigStateSelector = (state: RootState) => state.appConfigs;
export const appSettingsSelector = (state: RootState) => state.appConfigs.config;

export const getOrganizationICE = createSelector(appSettingsSelector, (config: AppSettings | null): string => {
  if (!config) return '';
  if (!config) return '';
  return config.ice;
});
