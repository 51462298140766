import { fromUnixTime } from 'date-fns';

export const isExpiredDate = (expiresIn: number): boolean => {
  const expiredDate = fromUnixTime(expiresIn);
  return expiredDate < new Date();
};

export const appHeaders = (token = '', useContentType: boolean = true) => {
  const headers = new Headers();

  headers.append('Accept', 'application/json');

  if (useContentType) {
    headers.append('Content-type', 'application/json');
  }

  if (token) {
    headers.append('authorization', `Bearer ${token}`);
  }

  return headers;
};
