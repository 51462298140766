import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircleLoader, InputText, LockIcon, TranslatedTextError, UserIcon } from 'src/components';
import { Screen } from 'src/components/Screen';
import { SText } from 'src/components/SText';
import { Footer } from 'src/containers/Footer';
import { authErrorSelector, authIsLoadingSelector, startLogInAction } from '../store';
import { useLoginForm } from './hooks';

export const LoginScreen = (): JSX.Element => {
  const { handleSubmit, errors, control } = useLoginForm();
  const isLoading = useSelector(authIsLoadingSelector);
  const errorLogin = useSelector(authErrorSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (data) => {
      dispatch(startLogInAction(data.email, data.password));
    },
    [dispatch],
  );

  // useEffect(()=> {
  //   dispatch(startLogInAction("chef@yopmail.com", "password"));
  // },[]);
  return (
    <>
      <Screen className="bg-white flex-col" center>
        <form
          className=" w-full md:w-1/2  px-4 justify-center flex py-2 flex-col items-center"
          style={{ maxWidth: 450 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-center flex justify-center flex-col">
            <SText label="Clico" classNames="text-2xl md:text-3xl text-black font-semibold" />
            <div className="text-center">
              <SText label="auth.login-sub-title" classNames="" />
            </div>
          </div>
          <Controller
            name="email"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <InputText
                  placeholder="email"
                  icon={<UserIcon />}
                  error={errors?.email}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          <Controller
            name="password"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <InputText
                  placeholder="password"
                  type="password"
                  icon={<LockIcon />}
                  error={errors?.password}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          {!!errorLogin && <TranslatedTextError message={errorLogin.message} />}
          {isLoading ? (
            <CircleLoader className="mt-3" />
          ) : (
            <Button type="submit" label="auth.cta-login" extraClasses="w-full mt-6" />
          )}
        </form>
      </Screen>
      <Footer />
    </>
  );
};
