import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSettingsState, SetAppConfigError, SetAppConfigLoading, UpdateAppConfig } from 'src/types';

export const initialState: AppSettingsState = {
  config: null,
  isLoading: false,
  error: null,
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setAppSettings(state, action: PayloadAction<UpdateAppConfig>) {
      state.config = action.payload;
    },
    setAppSettingsLoading(state, action: PayloadAction<SetAppConfigLoading>) {
      state.isLoading = action.payload.isLoading;
    },
    setAppSettingsError(state, action: PayloadAction<SetAppConfigError>) {
      state.error = action.payload.error;
    },
    resetAppSettings(state) {
      state.config = null;
    },
  },
});

export const { setAppSettings, setAppSettingsLoading, setAppSettingsError, resetAppSettings } =
  appSettingsSlice.actions;
export const appSettingReducer = appSettingsSlice.reducer;
