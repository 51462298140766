import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Controller, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, CircleLoader, InputText, LockIcon, TextError } from 'src/components';
import { useResetPassword } from './useResetPassword';
import { ResetPasswordInputs, useResetPasswordForm } from './useResetPasswordForm';

type ResetPasswordProps = {
  testID?: string;
};

type ResetPasswordParam = {
  token: string;
};

export const ResetPassword: React.FC<ResetPasswordProps> = (): JSX.Element => {
  const { token } = useParams<ResetPasswordParam>();
  const { handleSubmit, errors, control, reset } = useResetPasswordForm();
  const { loading, resetError, success, sendReset } = useResetPassword();
  const { t, i18n } = useTranslation();

  const onSubmit: SubmitHandler<ResetPasswordInputs> = ({ password }) => {
    sendReset(password, token);
  };

  useEffect(() => {
    if (success) {
      if (reset) {
        reset();
      }

      ReactGA.event({
        category: 'User',
        action: 'restPasswordSuccess',
        label: success.id,
      });
    }
  }, [success, reset]);

  const setLanguageToFR = () => {
    i18n.changeLanguage('fr');
  };

  const setLanguageToArabic = () => {
    i18n.changeLanguage('ar');
  };

  const currentLanguage = i18n.language;

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-sc text-gray-700">
      <form
        className="flex flex-col bg-white rounded shadow-lg p-12 mt-12 md:w-1/2 w-full mx-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!token ? (
          <TextError message={t('errors.bad-request')} />
        ) : !success ? (
          <>
            <h1 className="text-xl text-gray-500 font-normal">{t('auth.reset-password-form-title')}</h1>
            <Controller
              name="password"
              control={control}
              render={({ field: { onBlur, onChange } }) => {
                return (
                  <InputText
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="auth.reset-password-form-password"
                    type="password"
                    icon={<LockIcon />}
                    error={errors?.password}
                  />
                );
              }}
            ></Controller>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { onBlur, onChange } }) => {
                return (
                  <InputText
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="auth.reset-password-form-password-confirmation"
                    type="password"
                    icon={<LockIcon />}
                    error={errors?.confirmPassword}
                  />
                );
              }}
            ></Controller>

            {loading ? <CircleLoader /> : <Button label="global.cta-valid" extraClasses="mt-2" type="submit" />}

            {!!resetError && <TextError message={t(resetError.message)} />}
          </>
        ) : (
          <p className="text-center font-normal text-2xl text-green-600">{t('auth.reset-password-success')}</p>
        )}
      </form>

      <div className="fixed right-2 bottom-2">
        <ul className="text-gray-800 flex flex-row space-x-2 font-normal text-md">
          <li>
            <button
              onClick={setLanguageToFR}
              className={`cursor-pointer ${currentLanguage === 'fr' ? 'text-primary' : ''}`}
            >
              Français
            </button>
          </li>
          <li>
            <button
              onClick={setLanguageToArabic}
              className={`cursor-pointer ${currentLanguage === 'ar' ? 'text-primary' : ''}`}
            >
              العربية
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
