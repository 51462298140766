import { Action, Middleware } from 'redux';
import { EndPoints, LOGOUT_TRIGGERS } from 'src/config';
import { UserInfo, USER_REQUEST_UPDATE_INFO } from 'src/features/user';
import { setUserError, setUserLoading, updateUserInfo } from 'src/features/user/store';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers/auth';
import { RootState } from 'src/store';
import { isAuthenticatedSelector, logout, tokenSelector } from '../store';
import { resetAuth } from '../store/authSlice';

export const fetchUserInfoMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type } = action;

  if (type !== USER_REQUEST_UPDATE_INFO) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  // to valid if user is not authenticated then reset auth state
  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  next(setUserLoading({ isLoading: true }));

  try {
    const response = await fetch(EndPoints.userInfo, {
      method: 'POST',
      headers: appHeaders(token),
    });

    const json = await response.json();
    checkApiError(json);

    next(setUserError({ error: null }));

    const info = json as UserInfo;
    next(updateUserInfo({ info }));
  } catch (error) {
    if (error) {
      if (LOGOUT_TRIGGERS.includes((error as Error).message)) {
        api.dispatch(logout());
      }
    }
    console.error('error while loading user infos', error);
    next(setUserError({ error: error as Error }));
  } finally {
    next(setUserLoading({ isLoading: false }));
  }
};
