import { Action, Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers';
import { RootState } from 'src/store';
import { START_LOAD_ENTRIES_BOARD } from '../board.actions';
import { setEntries, setIsLoadingEntries, setLoadingErrorEntries } from '../boardSlice';

export const fetchEntriesBoardMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type } = action;

  if (type !== START_LOAD_ENTRIES_BOARD) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  next(setIsLoadingEntries({ isLoading: true }));

  next(setLoadingErrorEntries({ error: null }));
  try {
    const response = await fetch(EndPoints.entries, {
      method: 'POST',
      headers: appHeaders(token),
      body: JSON.stringify({
        fromDate: state.board.fromDate,
        toDate: state.board.toDate,
        initialAmount: state.board.showInitialAmount,
        clientId: state.board.currentClient,
        clientMode: state.board.clientMode,
      }),
    });

    const json = await response.json();
    checkApiError(json);

    next(setEntries({ entries: json }));
  } catch (e) {
    next(setLoadingErrorEntries({ error: e as Error }));
  } finally {
    next(setIsLoadingEntries({ isLoading: false }));
  }
};
