import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthSetErrorAction,
  AuthSetLoadingAction,
  AuthSetResetPasswordSuccessAction,
  AuthState,
  AuthUpdateAction,
} from '../auth.types';

export const LOGIN_ACTION_START = 'auth/login/start';
export const FORGOT_PASSWORD_RESET_PASSWORD_REQUEST = 'auth/password/forgot/send_email';

export type StartLogInAction = {
  type: typeof LOGIN_ACTION_START;
  payload: {
    email: string;
    password: string;
  };
};

export type StartForgotPasswordResetAction = {
  type: typeof FORGOT_PASSWORD_RESET_PASSWORD_REQUEST;
  payload: {
    email: string;
  };
};

const initialState: AuthState = {
  token: null,
  expiresIn: null,
  error: null,
  isLoading: false,
  resetPasswordSentSuccess: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.expiresIn = null;
      state.token = null;
    },
    loggedIn: (state, action: PayloadAction<AuthUpdateAction>) => {
      state.token = action.payload.token;
      state.expiresIn = action.payload.expiresIn;
    },
    setAuthLoading: (state, action: PayloadAction<AuthSetLoadingAction>) => {
      state.isLoading = action.payload.loading;
    },
    setAuthError: (state, action: PayloadAction<AuthSetErrorAction>) => {
      state.error = action.payload.error;
    },
    setResetPasswordSentSuccess: (state, action: PayloadAction<AuthSetResetPasswordSuccessAction>) => {
      state.resetPasswordSentSuccess = action.payload.resetPasswordSentSuccess;
    },
  },
});

export const { resetAuth, loggedIn, setAuthLoading, setAuthError, setResetPasswordSentSuccess } = authSlice.actions;
const authSlicerReducer = authSlice.reducer;
export { authSlicerReducer };
