import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationFR from './locale/fr-FR.json';
import translateArabic from './locale/ar-MA.json';

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translateArabic,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',
    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
