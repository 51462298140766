import React, { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SText } from 'src/components/SText';
import { FormsErrorParser } from 'src/containers/errors';

type Props = {
  type?: React.HTMLInputTypeAttribute | undefined;
  name?: string;
  extraClass?: string;
  inputClass?: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  icon?: ReactNode | null;
  error?: FieldError | undefined;
  textStyle?: React.CSSProperties | undefined;
  value?: string | number | readonly string[] | undefined;
  showUpLabel?: boolean;
  maxLength?: number;
  pattern?: string | undefined;
};

export const InputText: React.FC<Props> = ({
  type = 'text',
  name,
  extraClass,
  inputClass,
  placeholder,
  onBlur,
  onChange,
  onFocus,
  error,
  textStyle,
  value,
  icon = null,
  showUpLabel = false,
  maxLength,
  pattern,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const translatedPlaceholder = t(placeholder);

  const interactiveClasses = error ? 'border-red-500' : 'border-gray-400';
  return (
    <>
      {showUpLabel && (
        <SText classNames="block text-xl text-primary font-semibold my-1 text-left flex w-full" label={placeholder} />
      )}
      <div
        className={`flex flex-row space-x-1 rounded-md my-2 px-2 items-end md:shadow-md ${interactiveClasses} border py-2  text-gray-500 focus-within:text-gray-600  md:shadow-sc drop-shadow-sc ${extraClass} w-full`}
      >
        {icon}
        <input
          style={textStyle}
          type={type}
          name={name}
          value={value}
          className={` px-1 py-1   focus:outline-none font-light  placeholder-gray-300 ${inputClass} w-full`}
          placeholder={translatedPlaceholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          maxLength={maxLength}
          pattern={pattern}
        />
      </div>
      <FormsErrorParser error={error} />
    </>
  );
};
