export const LOGIN_ACTION_START = 'auth/login/start';
export const LOGOUT_ACTION_START = 'auth/logout/start';

export type StartLogInAction = {
  type: typeof LOGIN_ACTION_START;
  payload: {
    email: string;
    password: string;
  };
};

export interface AuthState {
  token: string | null;
  expiresIn: number | null;
  isLoading: boolean;
  error: Error | null;
  resetPasswordSentSuccess: string | null;
}

export type AuthUpdateAction = {
  token: string;
  expiresIn: number;
};

export type AuthSetLoadingAction = {
  loading: boolean;
};

export type AuthSetErrorAction = {
  error: Error | null;
};

export type AuthSetResetPasswordSuccessAction = {
  resetPasswordSentSuccess: string | null;
};

export type LogoutAction = {
  type: typeof LOGOUT_ACTION_START;
};
