import { FC, ReactNode } from "react"
import { HEADER_HEIGHT } from "src/config";

import styled from 'styled-components';

const Wrapper = styled.div`
    padding-top:${HEADER_HEIGHT + 10}px
`;

type Props =  {
    children:ReactNode;
    className?:string;
    center?:boolean;
}


export const Screen:FC<Props> = ({ center, className, children}) => {
    const centerClasses = center ? 'items-center justify-center' :'';

    return <Wrapper className={`flex w-screen h-screen ${centerClasses} ${className}`}>
        {children}
    </Wrapper>
}