import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { IDLE_TIMEOUT_IN_MS } from 'src/config';
import { logout } from 'src/features/auth';
type Props = {
  testID?: string;
  children: React.ReactNode;
};

export const AuthenticatedAppWrapper: React.FC<Props> = ({ children, testID = 'AuthenticatedAppWrapper' }) => {
  const dispatch = useDispatch();
  const handleOnIdle = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useIdleTimer({
    timeout: IDLE_TIMEOUT_IN_MS,
    onIdle: handleOnIdle,
    debounce: 800,
  });

  return <>{children}</>;
};
