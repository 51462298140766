import { UNKNOWN_ERROR } from 'src/consts';
import { AnyObject } from 'src/types';

type ApiResponseSuccess = AnyObject;

type ApiResponseError = {
  error: {
    status: number;
    codeMessage: string;
  };
};

export const checkApiError = (response: ApiResponseError | ApiResponseSuccess) => {
  if (response && response.error) {
    throw Error((response as ApiResponseError).error.codeMessage || UNKNOWN_ERROR);
  }
};
