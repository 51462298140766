import { Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { updateUserInfo } from 'src/features/user/store';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers/auth';
import { RootState } from 'src/store';
import { APP_SETTINGS_START_LOADING } from '../appSettings.actions';
import { setAppSettings, setAppSettingsError, setAppSettingsLoading } from '../appSettingsSlice';

export const appSettingsMiddleware: Middleware = (api) => (next) => async (action) => {
  const { type } = action;

  if (type !== APP_SETTINGS_START_LOADING) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  // to valid if user is not authenticated then reset auth state
  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  next(setAppSettingsLoading({ isLoading: true }));
  next(setAppSettingsError({ error: null }));

  try {
    const result = await fetch(EndPoints.appConfigs, {
      method: 'POST',
      headers: appHeaders(token),
    });

    const json = await result.json();

    checkApiError(json);

    next(setAppSettings(json));
  } catch (error) {
    next(setAppSettingsError({ error: error as Error }));
    if (
      error &&
      [
        'error_user_id_not_exist',
        'not_authorized',
        'organization_contract_not_set',
        'organization_contract_expired',
      ].includes((error as Error).message)
    ) {
      next(resetAuth());
      next(updateUserInfo({ info: null }));
      next(setAppSettingsError({ error: null }));
    }
  } finally {
    next(setAppSettingsLoading({ isLoading: false }));
  }
};
