import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddEntryToFactureUploadAction,
  AddEntryToValidateEntriesAction,
  AppendEntriesAction,
  BoardState,
  DeleteEntryOnEntriesTableAction,
  RemoveDeletingEntryAction,
  RemoveEntryToFactureUploadAction,
  RemoveEntryToValidateEntriesAction,
  SetClientModeAction,
  SetClientsAction,
  SetCurrentClientAction,
  setEntriesAction,
  SetFromDateAction,
  setIsLoadingClientsAction,
  setIsLoadingEntriesAction,
  setLoadingClientErrorAction,
  SetShownInitialAmountAction,
  SetToDateAction,
  setUploadingEntriesAction,
  setUploadingEntriesErrorAction,
  UpdateEntryDocumentAction,
  UpdateEntryStatusToValidateAction,
} from './board.types';

const initialState: BoardState = {
  showInitialAmount: false,
  fromDate: null,
  toDate: null,
  clientMode: false,
  currentClient: null,
  clients: [],
  clientsLoading: false,
  errorLoadingClients: null,
  entries: [],
  errorLoadingEntries: null,
  entriesLoading: false,
  uploadingEntries: false,
  uploadingEntriesError: null,
  selectedEntriesToDelete: [],
  factureUploadEntries: [],
  selectedEntriesToValid: [],
};

const boardSlice = createSlice({
  name: 'boardSlice',
  initialState,
  reducers: {
    setShownInitialAmount: (state, action: PayloadAction<SetShownInitialAmountAction>) => {
      state.showInitialAmount = action.payload.show;
    },
    setClientMode: (state, action: PayloadAction<SetClientModeAction>) => {
      state.clientMode = action.payload.clientMode;
    },
    setToDate: (state, action: PayloadAction<SetToDateAction>) => {
      state.toDate = action.payload.date;
    },
    appendEntries: (state, action: PayloadAction<AppendEntriesAction>) => {
      state.entries = [...state.entries, ...action.payload.entries];
    },
    setFromDate: (state, action: PayloadAction<SetFromDateAction>) => {
      state.fromDate = action.payload.date;
    },
    setCurrentClient: (state, action: PayloadAction<SetCurrentClientAction>) => {
      state.currentClient = action.payload.client;
    },
    setClients: (state, action: PayloadAction<SetClientsAction>) => {
      state.clients = action.payload.clients;
    },
    setIsLoadingClients: (state, action: PayloadAction<setIsLoadingClientsAction>) => {
      state.clientsLoading = action.payload.isLoading;
    },
    setLoadingErrorClients: (state, action: PayloadAction<setLoadingClientErrorAction>) => {
      state.errorLoadingClients = action.payload.error;
    },
    setEntries: (state, action: PayloadAction<setEntriesAction>) => {
      state.entries = action.payload.entries;
    },
    setIsLoadingEntries: (state, action: PayloadAction<setIsLoadingEntriesAction>) => {
      state.entriesLoading = action.payload.isLoading;
    },
    setUploadingEntries: (state, action: PayloadAction<setUploadingEntriesAction>) => {
      state.uploadingEntries = action.payload.isLoading;
    },
    setLoadingErrorEntries: (state, action: PayloadAction<setUploadingEntriesErrorAction>) => {
      state.errorLoadingEntries = action.payload.error;
    },
    setUploadingEntriesError: (state, action: PayloadAction<setUploadingEntriesErrorAction>) => {
      state.uploadingEntriesError = action.payload.error;
    },
    addDeletingEntry: (state, action: PayloadAction<RemoveDeletingEntryAction>) => {
      state.selectedEntriesToDelete.push(action.payload.id);
    },
    deleteEntryOnEntriesTable: (state, action: PayloadAction<DeleteEntryOnEntriesTableAction>) => {
      state.entries = state.entries.filter((entry) => entry.id !== action.payload.id);
    },
    removeDeletingEntry: (state, action: PayloadAction<RemoveDeletingEntryAction>) => {
      state.selectedEntriesToDelete = state.selectedEntriesToDelete.filter((entry) => entry !== action.payload.id);
    },
    addEntryToFactureList: (state, action: PayloadAction<AddEntryToFactureUploadAction>) => {
      state.factureUploadEntries.push(action.payload.id);
    },
    removeEntryFromFactureList: (state, action: PayloadAction<RemoveEntryToFactureUploadAction>) => {
      state.factureUploadEntries = state.factureUploadEntries.filter((entry) => entry !== action.payload.id);
    },
    addEntryToValidateStatusList: (state, action: PayloadAction<AddEntryToValidateEntriesAction>) => {
      state.selectedEntriesToValid.push(action.payload.id);
    },
    removeFromEntryToValidateStatusList: (state, action: PayloadAction<RemoveEntryToValidateEntriesAction>) => {
      state.selectedEntriesToValid = state.selectedEntriesToValid.filter((entry) => entry !== action.payload.id);
    },
    updateEntryDocument: (state, action: PayloadAction<UpdateEntryDocumentAction>) => {
      state.entries.map((entry) => {
        if (entry.id === action.payload.id) {
          entry.document = action.payload.document;
        }
      });
    },
    updateEntryStatusToValidate: (state, action: PayloadAction<UpdateEntryStatusToValidateAction>) => {
      state.entries.map((entry) => {
        if (entry.id === action.payload.id) {
          entry.status = 'VALIDATED';
        }
      });
    },
  },
});

export const {
  addEntryToFactureList,
  updateEntryStatusToValidate,
  removeFromEntryToValidateStatusList,
  addEntryToValidateStatusList,
  removeEntryFromFactureList,
  setShownInitialAmount,
  setCurrentClient,
  setLoadingErrorClients,
  setIsLoadingClients,
  setClients,
  setClientMode,
  updateEntryDocument,
  setFromDate,
  setEntries,
  setToDate,
  setIsLoadingEntries,
  setLoadingErrorEntries,
  setUploadingEntries,
  setUploadingEntriesError,
  appendEntries,
  addDeletingEntry,
  removeDeletingEntry,
  deleteEntryOnEntriesTable,
} = boardSlice.actions;

export const boardReducer = boardSlice.reducer;
