import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appSettingReducer } from './features/appSettings';
import { appSettingsMiddleware } from './features/appSettings/store/middleware';
import {
  fetchUserInfoMiddleware,
  loginMiddleware,
  logoutMiddleware,
  resetPasswordRequestMiddleware,
} from './features/auth/middleware';
import { authSlicerReducer } from './features/auth/store/authSlice';
import {
  deleteEntryBoardMiddleware,
  fetchClientsBoardMiddleware,
  fetchEntriesBoardMiddleware,
  submitEntryToValidateBoardMiddleware,
  uploadEntriesBoardMiddleware,
} from './features/SoldyBoard';
import { boardReducer } from './features/SoldyBoard/store/boardSlice';
import { uploadFactureEntryBoardMiddleware } from './features/SoldyBoard/store/middlewares/uploadFactureEntryBoardMiddleware';
import { useSlicerReducer } from './features/user/store';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['board', 'auth', 'appConfigs', 'user', 'appConfigs', 'board'],
};

// Remove the token and expiresIn if persist auth
const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['token', 'expiresIn', 'error', 'isLoading', 'resetPasswordSentSuccess'],
};

// remove config if you want to persist settings pp
const appConfigsPersistConfig = {
  key: 'appConfigs',
  storage,
  blacklist: ['config', 'error', 'isLoading'],
};

// remove entries if you want to persist entries showInitialAmount, entries
const boardPersistConfig = {
  key: 'board',
  storage,
  blacklist: [
    'entries',
    'showInitialAmount',
    'error',
    'isLoading',
    'toDate',
    'fromDate',
    'selectedEntriesToDelete',
    'uploadingEntriesError',
    'uploadingEntries',
    'errorLoadingClients',
    'clientsLoading',
    'clients',
    'currentClient',
    'clientMode',
    'factureUploadEntries',
    'selectedEntriesToValid',
  ],
};

// remove info in table if persist user
const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['info', 'error', 'isLoading'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlicerReducer),
  user: persistReducer(userPersistConfig, useSlicerReducer),
  appConfigs: persistReducer(appConfigsPersistConfig, appSettingReducer),
  board: persistReducer(boardPersistConfig, boardReducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
  loginMiddleware,
  resetPasswordRequestMiddleware,
  fetchUserInfoMiddleware,
  logoutMiddleware,
  appSettingsMiddleware,
  fetchClientsBoardMiddleware,
  fetchEntriesBoardMiddleware,
  uploadEntriesBoardMiddleware,
  deleteEntryBoardMiddleware,
  uploadFactureEntryBoardMiddleware,
  submitEntryToValidateBoardMiddleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleWares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
