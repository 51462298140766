export const $colors = {
  primary: '#0085FF',
  secondary: '#185FD8',
  blueLight: '#99CFFF',
  blueWhite: '#E2F2FF',
  black: '#000000',
  white: '#FFFFFF',
  dove: '#BFC0C0',
  dark: '#555555',
  error: '#D60000',
  success: '#14B8A6',
  warning: '#F97316',
  graySoft: '#E5E7EB',
  grayLight: '#F3F3F3',
  graySoDove: '#FAFAFA',
  pink: '#EC4899',
  redDove: '#FCDDEC',
  yellow: '#fdf7be',
};
