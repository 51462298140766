import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticatedSelector, tokenSelector, logout } from 'src/features/auth/store';

export const useToken = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logout());
    }
  }, [isAuthenticated]);

  return token;
};
