import { useCallback } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { SText } from './components/SText';
import { InitialProvider } from './containers';
import { MainApp } from './MainApp/MainApp';
import { persistor, store } from './store';
import { $colors } from './theme';

function App() {
  const { t } = useTranslation();

  const renderCookie = useCallback(() => {
    return (
      <CookieConsent
        location="bottom"
        buttonText={t('cookies-cta')}
        cookieName="smartColisCookies"
        style={{ background: $colors.primary }}
        buttonStyle={{ color: '#4e503b', fontSize: '16px', padding: '4px 8px' }}
        expires={150}
      >
        <SText label="cookies-notice" />
      </CookieConsent>
    );
  }, [t]);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <InitialProvider>
            <>
              <MainApp />
              {renderCookie()}
            </>
          </InitialProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
