import validator from 'validator';

type Row = any[];
type Rows = Row[];

const ICE_LENGTH = 15;

const notAllowedType = (type: number) => {
  return ![1, 2, 3, 4, 5, 6, 7, 8, 9].includes(type);
};

type ErrorEntry = { line: number; errorCode: string };

export const rowsNotValid = (rows: Rows) => {
  let hasError = false;

  let errors: ErrorEntry[] = [];

  rows.map((row, index): void => {
    const line = index + 1;
    if (!validator.isNumeric(row[0].toString())) {
      console.log('ice not number');
      errors.push({ line, errorCode: 'iceNotValid' });
      hasError = true;
    }

    if (row[0].toString().length !== ICE_LENGTH) {
      console.log(`ice not have length number ${ICE_LENGTH} `);
      errors.push({ line, errorCode: 'iceNotValid' });
      hasError = true;
    }

    if (!validator.isDate(row[1])) {
      console.log('date not valid date');
      errors.push({ line, errorCode: 'dateNotValid' });
      hasError = true;
    }

    if (!row[2]) {
      console.log('not pice number');
      errors.push({ line, errorCode: 'piceRequired' });
      hasError = true;
    }

    if (!row[3] || notAllowedType(row[3])) {
      console.log('not valid type');
      errors.push({ line, errorCode: 'typeNotValid' });
      hasError = true;
    }

    if (row[5]) {
      if (!validator.isNumeric(row[5].toString())) {
        console.log('not number debit');
        errors.push({ line, errorCode: 'debitNotValid' });
        hasError = true;
      }
    }

    if (row[6]) {
      if (!validator.isNumeric(row[6].toString())) {
        errors.push({ line, errorCode: 'creditNotValid' });
        console.log('not valid credit');
        hasError = true;
      }
    }

    // in case debit and credit given at onetime
    if (row[6] && row[5]) {
      console.log('credit and debit both are given');
      errors.push({ line, errorCode: 'creditOrdDebitAllowed' });
      hasError = true;
    }
  });

  return { hasError, errors };
};

const errorByCode = (error: ErrorEntry) => {
  switch (error.errorCode) {
    case 'iceNotValid': {
      return `ICE n'est pas valide`;
    }

    case 'dateNotValid': {
      return `La date n'est pas valide`;
    }

    case 'piceRequired': {
      return `Pièce n'est pas valide`;
    }

    case 'typeNotValid': {
      return `Type pièce n'est pas valide doit être L'un de ces valeurs: 1 2 3 4 5 6 7 8 ou 9`;
    }

    case 'debitNotValid': {
      return `Débit n'est pas valide"`;
    }

    case 'creditNotValid': {
      return `Crédit n'est pas valide"`;
    }

    case 'creditOrdDebitAllowed': {
      return `Fournir soit Mt débit ou crédit pas les deux ensemble`;
    }
  }
};

export const formatErrorsEntries = (errors: ErrorEntry[]): string => {
  let str = '';

  errors.map((error) => {
    str += `Ligne:<b className="text-red-600">"${error.line}</b>": ${errorByCode(error)}<br/> <br/>`;
  });
  return str;
};
