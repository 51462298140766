import { Action, Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers';
import { RootState } from 'src/store';
import { START_LOAD_CLIENTS_BOARD, START_LOAD_ENTRIES_BOARD } from '../board.actions';
import { StartUploadingEntriesAction, START_UPLOAD_ENTRIES_BOARD } from '../board.types';
import { setUploadingEntries, setUploadingEntriesError } from '../boardSlice';

export const uploadEntriesBoardMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type, entries } = action as StartUploadingEntriesAction;

  if (type !== START_UPLOAD_ENTRIES_BOARD) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  next(setUploadingEntries({ isLoading: true }));

  next(setUploadingEntriesError({ error: null }));
  try {
    const response = await fetch(EndPoints.uploadEntries, {
      method: 'POST',
      headers: appHeaders(token),
      body: JSON.stringify({
        entries,
      }),
    });

    const json = await response.json();
    checkApiError(json);

    api.dispatch({ type: START_LOAD_ENTRIES_BOARD });
    api.dispatch({ type: START_LOAD_CLIENTS_BOARD });
  } catch (e) {
    next(setUploadingEntriesError({ error: e as Error }));
  } finally {
    next(setUploadingEntries({ isLoading: false }));
  }
};
