import {
  EntryRawFromFile,
  StartDeleteEntryAction,
  StartUploadFactureAction,
  StartUploadingEntriesAction,
  StartValidateEntryAction,
  START_DELETE_ENTRY,
  START_UPLOAD_ENTRIES_BOARD,
  START_UPLOAD_FACTURE,
  START_VALIDATE_ENTRY,
} from './board.types';

export const START_LOAD_CLIENTS_BOARD = 'board/startLoad/client';
export const START_LOAD_ENTRIES_BOARD = 'board/startLoad/entries';

export const startLoadingBoard = () => {};

export const startUploadingEntries = (entries: EntryRawFromFile[]): StartUploadingEntriesAction => ({
  entries,
  type: START_UPLOAD_ENTRIES_BOARD,
});

export const startDeleteEntry = (id: string): StartDeleteEntryAction => ({
  id,
  type: START_DELETE_ENTRY,
});

export const startUploadFacture = (file: File, id: string): StartUploadFactureAction => ({
  file,
  id,
  type: START_UPLOAD_FACTURE,
});

export const startValidateEntry = (entryId: string): StartValidateEntryAction => ({
  id: entryId,
  type: START_VALIDATE_ENTRY,
});
