import Tooltip from '@material-ui/core/Tooltip';
import { Check, Delete, Download, MoreHoriz, UploadFile } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControlLabel, IconButton, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { CircleLoader } from 'src/components';
import { SText } from 'src/components/SText';
import { API_BASE_URL } from 'src/config';
import { EntriesBoardShortInfo } from 'src/types';
import { appConfigStateSelector } from '../appSettings';
import { useDownloadCSV, useShowUploadExcel } from './hooks';
import { boardSelector, clientListForSelectSelector, initialAmountCheckedSelector } from './store';
import {
  startDeleteEntry,
  startUploadFacture,
  startUploadingEntries,
  startValidateEntry,
  START_LOAD_CLIENTS_BOARD,
  START_LOAD_ENTRIES_BOARD,
} from './store/board.actions';
import { EntryRawFromFile } from './store/board.types';
import {
  addDeletingEntry,
  addEntryToFactureList,
  addEntryToValidateStatusList,
  setClientMode,
  setCurrentClient,
  setFromDate,
  setShownInitialAmount,
  setToDate,
  setUploadingEntriesError,
} from './store/boardSlice';
import { formatErrorsEntries, rowsNotValid } from './uploadValidator';

type Props = {
  testID?: string;
};

export const SoldyBoard: React.FC<Props> = ({ testID = 'SoldyBoard' }) => {
  const clientsSelect = useSelector(clientListForSelectSelector);
  const {
    showToggleMode,
    showUploadExcel,
    isClientEditor,
    adminLevel,
    isProviderEditor,
    isClientViewer,
    isProviderViewer,
  } = useShowUploadExcel();

  const appConfig = useSelector(appConfigStateSelector);
  const [showDeleteDialog, setShowDialog] = useState<boolean>(false);
  const [showValidEntryStatus, setShowValidEntryStatus] = useState<boolean>(false);

  const [selectedEntryToDelete, setEntryToDelete] = useState<string | null>(null);
  const [selectedEntryToValidStatus, setSelectedEntryToValidStatus] = useState<string | null>(null);

  const downloadCSV = useDownloadCSV();
  const board = useSelector(boardSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: START_LOAD_CLIENTS_BOARD });
    dispatch({ type: START_LOAD_ENTRIES_BOARD });
  }, [dispatch]);

  const onChangeFromDate = useCallback(
    (date: Date | null) => {
      dispatch(setFromDate({ date }));
    },
    [dispatch],
  );

  const onChangeToDate = useCallback(
    (date: Date | null) => {
      dispatch(setToDate({ date }));
    },
    [dispatch],
  );

  const initialAmountChecked = useSelector(initialAmountCheckedSelector);

  const handleChange = useCallback(
    (event: any) => {
      dispatch(setCurrentClient({ client: event.target.value as unknown as string }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch({ type: START_LOAD_ENTRIES_BOARD });
  }, [board.currentClient, board.toDate, board.fromDate, board.showInitialAmount, dispatch, board.clientMode]);

  useEffect(() => {
    dispatch({ type: START_LOAD_CLIENTS_BOARD });
  }, [board.clientMode, dispatch]);

  const toggleHandler = useCallback(() => {
    dispatch(setShownInitialAmount({ show: !initialAmountChecked }));
  }, [dispatch, initialAmountChecked]);

  useEffect(() => {}, [board.errorLoadingEntries, setShowValidEntryStatus]);

  const onUploadNewExcel = useCallback(
    (ev) => {
      readXlsxFile(ev.target.files[0]).then((rows) => {
        const [, ...entriesList] = rows;

        const errorsResults = rowsNotValid(entriesList);
        if (errorsResults.hasError) {
          dispatch(
            setUploadingEntriesError({
              error: new Error(
                `Le fichier contient des erreurs de format veuillez vérifier le fichier excel: ${formatErrorsEntries(
                  errorsResults.errors,
                )}`,
              ),
            }),
          );
        } else {
          dispatch(startUploadingEntries(entriesList as unknown as EntryRawFromFile[]));
        }

        // rest file
        ev.target.value = null;
      });
    },
    [dispatch],
  );

  const openDeleteDialogForEntry = useCallback(
    (entryId: string) => () => {
      setEntryToDelete(entryId);
      setShowDialog(true);
    },
    [setEntryToDelete, setShowDialog],
  );

  const openValidEntryStatusDialogForEntry = useCallback(
    (entryId: string) => () => {
      setSelectedEntryToValidStatus(entryId);
      setShowValidEntryStatus(true);
    },
    [setSelectedEntryToValidStatus, setShowValidEntryStatus],
  );

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleCloseValidEntryStatus = useCallback(() => {
    setShowValidEntryStatus(false);
  }, [setShowDialog]);

  const onConfirm = useCallback(() => {
    setShowDialog(false);
    if (selectedEntryToDelete) {
      // start real action
      dispatch(addDeletingEntry({ id: selectedEntryToDelete }));
      dispatch(startDeleteEntry(selectedEntryToDelete));
    }
  }, [setShowDialog, selectedEntryToDelete, dispatch]);

  const onConfirmValidEntryStatus = useCallback(() => {
    setShowValidEntryStatus(false);
    if (selectedEntryToValidStatus) {
      // start real action
      dispatch(addEntryToValidateStatusList({ id: selectedEntryToValidStatus }));
      dispatch(startValidateEntry(selectedEntryToValidStatus));
    }
  }, [setShowValidEntryStatus, selectedEntryToValidStatus, dispatch]);

  const tutaux = useMemo(() => {
    const totalDebit = board.entries.reduce((acc, current) => {
      acc += current.debit ?? 0;
      return acc;
    }, 0);

    const totalCredit = board.entries.reduce((acc, current) => {
      acc += current.credit ?? 0;
      return acc;
    }, 0);

    const total = board.clientMode ? totalCredit - totalDebit : totalDebit - totalCredit;

    return {
      totalDebit,
      totalCredit,
      total,
    };
  }, [board.entries, board.clientMode]);

  const uploadFacture = useCallback(
    (entryId) => (ev: any) => {
      dispatch(addEntryToFactureList({ id: entryId }));
      dispatch(startUploadFacture(ev.target.files[0] as File, entryId));
    },
    [dispatch],
  );

  const toggleClientMode = useCallback(() => {
    dispatch(setClientMode({ clientMode: !board.clientMode }));
  }, [dispatch, board.clientMode]);

  const onCloseErrorUploadModal = useCallback(() => {
    dispatch(setUploadingEntriesError({ error: null }));
  }, [dispatch]);

  const creditInput = useCallback(
    (entry: EntriesBoardShortInfo) => {
      if (!board.clientMode) {
        return entry.credit
          ? Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
              .format(entry.credit as number)
              .replace('MAD', '')
          : '';
      }

      return entry.debit
        ? Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
            .format(entry.debit as number)
            .replace('MAD', '')
        : '';
    },
    [board.clientMode],
  );

  const debitInput = useCallback(
    (entry: EntriesBoardShortInfo) => {
      if (!board.clientMode) {
        return entry.debit
          ? Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
              .format(entry.debit as number)
              .replace('MAD', '')
          : '';
      }

      return entry.credit
        ? Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
            .format(entry.credit as number)
            .replace('MAD', '')
        : '';
    },
    [board.clientMode],
  );

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const htmlRenderToolTip = (description: string) => (
    <div
      style={{ fontSize: '16px', fontFamily: 'Arial', lineHeight: '18px' }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        open={!!board.uploadingEntriesError}
        onClose={onCloseErrorUploadModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Erreur
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: t(board.uploadingEntriesError?.message ?? 'Erreur inconnue') }} />
        </Box>
      </Modal>

      <Dialog
        open={showDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer cette opération ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button onClick={onConfirm} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showValidEntryStatus}
        onClose={handleCloseValidEntryStatus}
        aria-labelledby="validate-entry-status"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="validate-entry-status">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment accepter cette opération ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidEntryStatus}>Non</Button>
          <Button onClick={onConfirmValidEntryStatus} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>

      <div data-testid={testID} className="pt-10 md:px-2 px-1 bg-white">
        <div
          className="flex mx-1  items-center justify-between  m-auto bg-gray-300 p-2 max-w-screen-md"
          style={{ margin: '16px auto', maxWidth: '85%' }}
        >
          <SText label="board.title" classNames="text-xl md:text-2xl text-gray-800 my-2 " />

          {showToggleMode && (
            <div className="ml-4 flex flex-row items-center">
              <div className="mr-4">Mode:</div>
              <div>Fournisseur</div>
              <div className="ml-4 ">
                <FormControlLabel
                  control={<Switch checked={board.clientMode} onChange={toggleClientMode} name="gilad" />}
                  label=""
                />
              </div>
              <div>
                <span className="">Client</span>
              </div>
            </div>
          )}
          {!showToggleMode && (isClientViewer || isProviderViewer || isClientEditor || isProviderEditor) && (
            <div className="ml-4 flex flex-row items-center">
              <div className="mr-2">Mode:</div>
              <div>{isClientViewer || isClientEditor ? 'Client' : 'Fournisseur'}</div>
            </div>
          )}
        </div>

        <div className="m-auto my-3" style={{ maxWidth: '85%' }}>
          <div className="flex flex-row items-center">
            {/* <div className="ml-2">
              <FormControlLabel
                control={<Checkbox checked={initialAmountChecked} onChange={toggleHandler} />}
                label="Solde initial"
              />
            </div> */}
            <div className="ml-2">
              <DesktopDatePicker
                label="Du"
                inputFormat="dd/MM/yyyy"
                value={board.fromDate}
                onChange={onChangeFromDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className="ml-2">
              <DesktopDatePicker
                label="Au"
                inputFormat="dd/MM/yyyy"
                value={board.toDate}
                onChange={onChangeToDate}
                renderInput={(params) => <TextField {...params} />}
                minDate={board.fromDate ?? undefined}
              />
            </div>

            <div className="ml-2">
              {board.clientsLoading ? (
                <CircleLoader />
              ) : (
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="select-client-providers">
                    {t(!board.clientMode ? 'board.table-client' : 'board.table-provider')}
                  </InputLabel>
                  <Select
                    labelId="select-client-providers"
                    id="select-client-providers"
                    value={board.currentClient}
                    label={!board.clientMode ? 'Clients' : 'Fournisseurs'}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>{board.clientMode ? 'Tous les fournisseurs' : 'Tous les clients'}</em>
                    </MenuItem>
                    {clientsSelect.map((client) => {
                      return (
                        <MenuItem value={client.id} defaultChecked={client.id === board.currentClient}>
                          {client.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>

            {!!board.entries.length && (
              <div className="ml-2">
                <Button endIcon={<Download />} variant="contained" onClick={downloadCSV}>
                  Download CSV
                </Button>
              </div>
            )}
            {showUploadExcel && (
              <div className="ml-2">
                {board.uploadingEntries ? (
                  <CircleLoader />
                ) : (
                  <Button endIcon={<UploadFile />} variant="contained" color="success" component="label">
                    Upload Excel
                    <input type="file" hidden onChange={onUploadNewExcel} id="excelUploader" />
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="m-auto" style={{ maxWidth: '85%' }}>
          {board.entriesLoading ? (
            <CircleLoader />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: '650' }} aria-label="simple table">
                <TableHead className="bg-gray-100">
                  <TableRow>
                    <TableCell>{t('board.table-id')}</TableCell>
                    <TableCell>{t('board.table-sys-date')}</TableCell>
                    <TableCell>{t('board.table-date')}</TableCell>
                    <TableCell>{t(board.clientMode ? 'board.table-provider' : 'board.table-client')}</TableCell>
                    <TableCell>{t('board.table-item')}</TableCell>
                    <TableCell>{t('board.table-item-type')}</TableCell>
                    <TableCell>{t('board.table-description')}</TableCell>
                    <TableCell>{t('board.table-amount-deb')}</TableCell>
                    <TableCell>{t('board.table-amount-credit')}</TableCell>
                    <TableCell>{t('board.table-actions')}</TableCell>
                    <TableCell>{t('board.table-status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {board.entries.map((entry) => {
                    const referenceIndex = entry.reference.split('-');
                    const reference = referenceIndex[referenceIndex.length - 1];

                    const isSameClient = appConfig?.config?.id === entry.organizationId && entry.organizationId;
                    return (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={entry.id}>
                        <TableCell component="th" scope="row">
                          {reference}
                        </TableCell>
                        <TableCell>
                          {format(new Date(entry.createdAt), 'dd/MM/yyyy')}
                          {!!entry.user && (
                            <Tooltip
                              title={htmlRenderToolTip(
                                t('board.created-by', {
                                  name: `${entry.user.firstName} ${entry.user.lastName}`,
                                  email: entry.user.email,
                                  date: entry.createdAt ? format(new Date(entry.createdAt), 'dd/MM/yyyy HH:mm') : null,
                                }),
                              )}
                              arrow
                            >
                              <span>
                                <MoreHoriz htmlColor="#ccc" />
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>{format(new Date(entry.date), 'dd/MM/yyyy')}</TableCell>
                        <TableCell>{entry.client.name ?? entry.client.ice}</TableCell>
                        <TableCell>{entry.piece}</TableCell>
                        <TableCell>{t(entry.type)}</TableCell>
                        <TableCell>
                          {entry.description.length > 25 ? (
                            <Tooltip title={htmlRenderToolTip(entry.description)} arrow>
                              <span>
                                {entry.description.substring(0, 25)}
                                <MoreHoriz htmlColor="#ccc" />
                              </span>
                            </Tooltip>
                          ) : (
                            <span>{entry.description}</span>
                          )}
                        </TableCell>
                        <TableCell>{debitInput(entry)}</TableCell>
                        <TableCell>{creditInput(entry)}</TableCell>
                        <TableCell>
                          {showUploadExcel && (adminLevel || isProviderEditor) && (
                            <>
                              {entry.status === 'PENDING'
                                ? isSameClient &&
                                  (board.selectedEntriesToDelete.some((item) => item === entry.id) ? (
                                    <LoadingButton loading></LoadingButton>
                                  ) : (
                                    <IconButton color="error" onClick={openDeleteDialogForEntry(entry.id)}>
                                      <Delete />
                                    </IconButton>
                                  ))
                                : null}
                            </>
                          )}
                          {!!entry.document && (
                            <a color="primary" href={`${API_BASE_URL}${entry.document}`} download target="_black">
                              <Download color="primary" />
                            </a>
                          )}
                          {entry.status === 'PENDING' &&
                            isSameClient &&
                            (isProviderEditor || adminLevel) &&
                            !board.clientMode && (
                              <IconButton color="secondary" component="label">
                                {board.factureUploadEntries.some((item) => item === entry.id) ? (
                                  <LoadingButton loading></LoadingButton>
                                ) : (
                                  <UploadFile />
                                )}
                                <input
                                  type="file"
                                  hidden
                                  accept="application/pdf, application/vnd.ms-excel"
                                  onChange={uploadFacture(entry.id)}
                                />
                              </IconButton>
                            )}
                        </TableCell>
                        <TableCell>
                          {entry.client.ice &&
                          entry.status === 'PENDING' &&
                          board.clientMode &&
                          (isClientEditor || adminLevel) ? (
                            <IconButton color="primary" onClick={openValidEntryStatusDialogForEntry(entry.id)}>
                              {board.selectedEntriesToValid.some((item) => item === entry.id) ? (
                                <LoadingButton loading></LoadingButton>
                              ) : (
                                <Check />
                              )}
                            </IconButton>
                          ) : (
                            <>
                              {t(entry.status)}
                              {board.clientMode && entry.validatedBy && (
                                <Tooltip
                                  title={htmlRenderToolTip(
                                    t('board.validated-by', {
                                      name: `${entry.validatedBy.firstName} ${entry.validatedBy.lastName}`,
                                      email: entry.validatedBy.email,
                                      date: entry.validatedDate
                                        ? format(new Date(entry.validatedDate), 'dd/MM/yyyy HH:mm')
                                        : null,
                                    }),
                                  )}
                                  arrow
                                >
                                  <span>
                                    <MoreHoriz htmlColor="#ccc" />
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter className="bg-gray-300">
                  <TableCell rowSpan={8} />
                  <TableCell colSpan={5}></TableCell>
                  <TableCell>
                    <div className=" text-gray-600 text-xl uppercase font-light">Totaux</div>
                  </TableCell>
                  <TableCell>
                    <div className="text-xl font-light">
                      {Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
                        .format(tutaux.totalDebit as number)
                        .replace('MAD', '')}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-xl font-light">
                      {Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
                        .format(tutaux.totalCredit as number)
                        .replace('MAD', '')}
                    </div>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <div className="md:text-2xl text-xl font-semibold text-gray-800 text-center">
                      {Intl.NumberFormat('fr-MA', { style: 'currency', currency: 'MAD' })
                        .format(Math.abs(tutaux.total) as number)
                        .replace('MAD', '')}
                      {Math.abs(tutaux.total) > 0 && `${tutaux.total >= 0 ? 'D' : 'C'}`}
                    </div>
                  </TableCell>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};
