import { OpenInNew } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SText } from 'src/components/SText';
import { INVOICE_SERVER, SCRoutes } from 'src/config';
import { appSettingsSelector } from 'src/features/appSettings';
import { isAuthenticatedSelector } from 'src/features/auth';
import { LOGOUT_ACTION_START } from 'src/features/auth/auth.types';
import { userSelector } from 'src/features/user/store';
import styled from 'styled-components';
import { UserHeader } from './UserHeader';

const Wrapper = styled.div`
  height: 50px;
`;

type Props = {
  testID?: string;
};

export const SiteHeader: React.FC<Props> = () => {
  const [invoiceServer, setInvoiceServer] = useState<string>();
  const history = useHistory();
  const { t } = useTranslation();
  const { info } = useSelector(userSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const appSettings = useSelector(appSettingsSelector);
  const [isSignUp, setIsSingUp] = useState<boolean>(false);

  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch({ type: LOGOUT_ACTION_START });
  }, [dispatch]);

  const goToSignUp = useCallback(() => {
    history.push(SCRoutes.signup);
  }, [history]);

  const goToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    setIsSingUp(history.location.pathname.includes('signup'));
    console.log('@path', history.location.pathname);
  }, [history.location.pathname, setIsSingUp]);

  //const isSignUp = SCRoutes.signup.endsWith(history.location.pathname);

  const organizationName = appSettings?.name ?? appSettings?.headquarter;

  useEffect(() => {
    if (appSettings) {
      const urlInvoice = `${INVOICE_SERVER}?company=${appSettings.invoiceFooter ?? ''}`;
      setInvoiceServer(urlInvoice);
    }
  }, [appSettings, setInvoiceServer]);

  return (
    <Wrapper className="bg-secondary py-2 px-4 text-white  flex items-center space-x-2 flex-row justify-between fixed w-screen z-10">
      <div className="flex flex-row">
        <a onClick={goToHome} href="" className="cursor-pointer">
          <h1 className="font-semibold text-xl">CLICO</h1>
        </a>
        {!!organizationName && (
          <div className="text-white text-lg ml-2 border-l border-blue-50 pl-2">
            <SText label={organizationName ?? ''} classNames="uppercase" />
          </div>
        )}
      </div>
      <div className="flex flex-row space-x-2 items-center uppercase">
        {!!info && info.firstName && <UserHeader userName={`${info?.firstName} ${info?.lastName?.toUpperCase()}`} />}
        {isAuthenticated && (
          <>
            <button className="bg-secondary py-2 px-2 border rounded-md border-white mr-3 normal-case" onClick={logout}>
              {t('menu.logout')}
            </button>

            <a
              className="bg-secondary py-2 px-2 border rounded-md border-white mr-3 normal-case"
              href={invoiceServer}
              target="_blank"
            >
              {t('menu.invoice-generator')}
              <OpenInNew className="mx-1" />
            </a>
          </>
        )}

        {!isAuthenticated && (
          <a
            className="bg-secondary py-2 px-2 border rounded-md border-white mr-3 normal-case"
            onClick={!isSignUp ? goToSignUp : goToHome}
            href=""
          >
            {t(!isSignUp ? 'menu.request-auth' : 'menu.login')}
          </a>
        )}
      </div>
    </Wrapper>
  );
};
