import React from 'react';
import { SText } from 'src/components/SText';

type Props = {
  testID?:string;
  userName:string;
}

export const UserHeader: React.FC<Props> = ({ userName, testID='UserHeader', }) => {
return <div data-testid={testID} className="">
    <SText label="user.hello-name" translatingOptions={{name:userName}}/>
</div>
}