import React from 'react';
import { FieldError } from 'react-hook-form';
import { TextError } from 'src/components';
import { useFormErrorParser } from './useFormErrorParser';

type Props = {
  error: FieldError | undefined;
};

export const FormsErrorParser: React.FC<Props> = ({ error }): JSX.Element | null => {
  const errorMessage = useFormErrorParser(error);

  if (!errorMessage) {
    return null;
  }

  return <TextError message={errorMessage} />;
};
