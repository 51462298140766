import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { errorsInput } from 'src/consts';
import * as Yup from 'yup';
import { SignUpInput } from '../signup.types';

export const useSignUp = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    headquarter: Yup.string().required(errorsInput.required),
    ice: Yup.string()
      .required(errorsInput.required)
      .matches(/[0-9]{15}/, t(errorsInput.invalideICEFormat)),
    firstName: Yup.string().required(errorsInput.required),
    lastName: Yup.string().required(errorsInput.required),
    tel: Yup.string()
      .required(errorsInput.required)
      .matches(/[0-9]{10}/, t(errorsInput.phoneInvalid)),
    email: Yup.string().required(errorsInput.required).email(t(errorsInput.emailInvalidFormat)),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SignUpInput>(formOptions);

  return {
    handleSubmit,
    errors,
    reset,
    control,
  };
};
