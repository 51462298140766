import { Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { USER_REQUEST_UPDATE_INFO } from 'src/features/user';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers/auth';
import { LOGIN_ACTION_START, StartLogInAction } from '../auth.types';
import { loggedIn, setAuthError, setAuthLoading } from '../store/authSlice';

export const loginMiddleware: Middleware = () => (next) => async (action) => {
  const { type } = action;

  if (type !== LOGIN_ACTION_START) {
    next(action);
    return;
  }

  const {
    payload: { email, password },
  } = action as StartLogInAction;

  next(setAuthLoading({ loading: true }));

  try {
    const response = await fetch(EndPoints.login, {
      headers: appHeaders(),
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const json = await response.json();
    checkApiError(json);

    const { token, expiresIn } = json;
    if (token && expiresIn) {
      next(setAuthError({ error: null }));
      next(
        loggedIn({
          token,
          expiresIn: expiresIn,
        }),
      );
      // fetch User Info
      next({ type: USER_REQUEST_UPDATE_INFO });
    }
  } catch (error) {
    next(setAuthError({ error: error as Error }));
  } finally {
    next(setAuthLoading({ loading: false }));
  }
};
