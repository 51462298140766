import { format } from 'date-fns';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { boardSelector } from '../store';

export const useDownloadCSV = () => {
  const board = useSelector(boardSelector);
  const generateCSV = useCallback(() => {
    //define the heading for each row of the data
    let csv = `Reference;${
      board.clientMode ? 'Fournisseurs' : 'Clients'
    };Date Création;Date; NB piece; Type; Designation; MT Debit; MT credit\n`;

    //merge the data with CSV
    board.entries.map((entry) => {
      csv += `${entry.reference};${entry.client.name ?? entry.client.ice};${format(
        new Date(entry.createdAt),
        'dd/MM/yyyy',
      )};${format(new Date(entry.date), 'dd/MM/yyyy')};${entry.piece};${entry.type};${entry.description}; ${
        entry.debit
      };${entry.credit} \n`;
    });

    // //display the created CSV data on the web browser
    // document.write(csv);

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    const date = new Date();

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `Export-${format(date, 'dd-MM-yyyy-HHmmss')}.csv`;
    hiddenElement.click();
  }, [board]);

  return generateCSV;
};
