export const errorsInput = {
  required: 'forms.required-field',
  minLengthPassword: 'forms.min-length-field',
  minStringLength: 'forms.min-length-field',
  maxLengthPassword: 'forms.max-length-field',
  matchPassword: 'forms.should-match-password',
  emailInvalidFormat: 'forms.email-invalid-format',
  unknownError: 'unknown-errors',
  phoneInvalid: 'forms.invalid-phone-format',
  moreThen: 'forms.number-should-more-then',
  shouldBeNumber: 'forms.should-be-number',
  invalidColiTrackingNumber: 'forms.invalid-coli-tracking-number',
  codeSecureColiInvalid: 'public.invalid-code-secure-tracking-public',
  priceShouldBeMoreOrEqualThenTotal: 'forms.price-should-be-more-or-equal-total',
  maxStringLength: 'string-is-max-then-allow',
  invalideICEFormat: 'ice-format-not-valid',
};

export const UNKNOWN_ERROR = 'unknown_error';
export const UNAUTHORIZED = 'unauthorized';
