import React from 'react';

type Props = {
  height?:number;
  width?:number;
  className?:string
}

export const CircleLoader: React.FC<Props> = ({className, width = 30, height=30}): JSX.Element => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500" style={{height:`${height}px`, width:`${width}px`}}></div>
    </div>
  );
};
