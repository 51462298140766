import { IdModel } from 'src/types';

export const USER_UPDATE_INFO = 'user/update/info';
export const USER_REQUEST_UPDATE_INFO = 'user/request/update/info';

export const UserRole = {
  ADMIN: 'ADMIN',
  SUPPERADMIN: 'SUPPERADMIN',
} as const;

export const Language = {
  fr_FR: 'fr_FR',
  ar_MA: 'ar_MA',
} as const;

export type UserState = {
  info?: UserInfo | null;
  isLoading: boolean;
  error: Error | null;
};

export type UserInfo = {
  id: IdModel;
  firstName: string;
  lastName: string;
  role: keyof typeof UserRole;
  capabilities: string[];
  email: string;
  tel?: string;
  city?: string;
  country?: string;
  address?: string;
  language: keyof typeof Language;
};

export type UpdateUserInfoAction = {
  info: UserInfo | null;
};

export type UpdateUserLoadingPayload = {
  isLoading: boolean;
};

export type UpdateUserErrorPayload = {
  error: Error | null;
};

export const capabilities = {
  clientViewer: 'clientViewer',
  clientEditor: 'clientEditor',
  providerViewer: 'providerViewer',
  providerEditor: 'providerEditor',
};
