import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message: string;
  className?: string;
};

export const TextError: React.FC<Props> = ({ className, message }): JSX.Element => {
  const {t} = useTranslation();
  return <span className={`block my-1 text-red-500 font-light ${className}`}>{t(message)}</span>;
};
