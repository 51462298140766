import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Button, CircleLoader, InputText, TranslatedTextError } from 'src/components';
import { Screen } from 'src/components/Screen';
import { SText } from 'src/components/SText';
import { Footer } from 'src/containers/Footer';
import { useSignUp, useSubmitSignUp } from './hooks';

type Props = {
  testID?: string;
};

export const SignUpScreen: React.FC<Props> = ({ testID = 'SignUpScreen' }) => {
  const { handleSubmit, errors, control } = useSignUp();
  const { isLoading, error, submitSignUpRequest, data } = useSubmitSignUp();

  const onSubmit = useCallback(
    (data) => {
      submitSignUpRequest(data);
    },
    [submitSignUpRequest],
  );

  if (data) {
    return (
      <Screen className="bg-white flex-col" center>
        <div className="m-auto mx-2 my-2 text-center">
          <SText label="signUp.success-message" classNames="text-green-500 text-xl md:text-2xl text-center" />
        </div>
      </Screen>
    );
  }

  return (
    <>
      <Screen className="bg-white flex-col" center>
        <form
          className=" w-full md:w-1/2  px-4 justify-center flex py-1  flex-col"
          style={{ maxWidth: 450 }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="text-center flex justify-center flex-col">
            <SText label="CLICO" classNames="text-2xl md:text-3xl text-black font-semibold" />
            <div className="text-center">
              <SText label="signUp.sub-title" classNames="" />
            </div>
          </div>
          <Controller
            name="headquarter"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <InputText
                  placeholder="signUp.headquarter"
                  error={errors?.headquarter}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          <Controller
            name="ice"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <div className="w-full">
                  <InputText
                    placeholder="signUp.ice"
                    error={errors?.ice}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={15}
                    pattern={'[0-1]'}
                  />
                </div>
              );
            }}
          />

          <div className="flex flex-row justify-between">
            <div>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onBlur, onChange } }) => {
                  return (
                    <InputText
                      placeholder="signUp.first-name"
                      error={errors?.firstName}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onBlur, onChange } }) => {
                  return (
                    <InputText
                      placeholder="signUp.last-name"
                      extraClass="ml-2"
                      error={errors?.lastName}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />
            </div>
          </div>
          <Controller
            name="tel"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <InputText
                  placeholder="signUp.tel"
                  error={errors?.tel}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="tel"
                  maxLength={10}
                />
              );
            }}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { onBlur, onChange } }) => {
              return (
                <InputText
                  placeholder="signUp.email"
                  error={errors?.email}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="email"
                />
              );
            }}
          />

          {!!error && <TranslatedTextError message={error.message} />}
          {isLoading ? (
            <CircleLoader className="mt-3" />
          ) : (
            <Button type="submit" label="signUp.sign-up-cta" extraClasses="w-full mt-6" />
          )}
        </form>
      </Screen>
      <Footer />
    </>
  );
};
