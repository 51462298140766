import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { errorsInput } from 'src/consts';
import { useTranslation } from 'react-i18next';

export type LoginInputs = {
  email: string;
  password: string;
};

export const useLoginForm = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(errorsInput.required).email(t(errorsInput.emailInvalidFormat)),
    password: Yup.string().required(errorsInput.required).test('len', t(errorsInput.minStringLength, { min: 4 }), (val) => !!(val && val.length > 3)),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<LoginInputs>(formOptions);

  return {
    handleSubmit,
    errors,
    reset,
    control,
  };
};
