import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { errorsInput } from 'src/consts';
import { formsGlobal } from 'src/config';
import { useTranslation } from 'react-i18next';

export type ResetPasswordInputs = {
  password: string;
  confirmPassword: string;
};

export const useResetPasswordForm = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(errorsInput.required)
      .min(formsGlobal.password.minLength, t(errorsInput.minLengthPassword, { min: formsGlobal.password.minLength }))
      .max(formsGlobal.password.maxLength, t(errorsInput.maxLengthPassword, { max: formsGlobal.password.maxLength })),
    confirmPassword: Yup.string()
      .required(errorsInput.required)
      .oneOf([Yup.ref('password')], t(errorsInput.matchPassword)),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordInputs>(formOptions);

  return {
    handleSubmit,
    errors,
    reset,
    control,
  };
};
