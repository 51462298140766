import { Action, Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers';
import { RootState } from 'src/store';
import { START_LOAD_CLIENTS_BOARD } from '../board.actions';
import { setClients, setIsLoadingClients, setLoadingErrorClients } from '../boardSlice';

export const fetchClientsBoardMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type } = action;

  if (type !== START_LOAD_CLIENTS_BOARD) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  next(setIsLoadingClients({ isLoading: true }));

  next(setLoadingErrorClients({ error: null }));
  try {
    const response = await fetch(EndPoints.clients, {
      method: 'POST',
      headers: appHeaders(token),
      body: JSON.stringify({
        clientMode: state.board.clientMode,
      }),
    });

    const json = await response.json();
    checkApiError(json);

    next(setClients({ clients: json }));
  } catch (e) {
    next(setLoadingErrorClients({ error: e as Error }));
  } finally {
    next(setIsLoadingClients({ isLoading: false }));
  }
};
