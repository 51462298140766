import { Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { checkApiError } from 'src/handlers';
import {
  FORGOT_PASSWORD_RESET_PASSWORD_REQUEST,
  setAuthError,
  setAuthLoading,
  setResetPasswordSentSuccess,
  StartForgotPasswordResetAction,
} from '../store/authSlice';

export const resetPasswordRequestMiddleware: Middleware = () => (next) => async (action) => {
  const { type } = action;

  if (type !== FORGOT_PASSWORD_RESET_PASSWORD_REQUEST) {
    next(action);
    return;
  }

  try {
    const {
      payload: { email },
    } = action as StartForgotPasswordResetAction;

    next(setAuthLoading({ loading: true }));

    const response = await fetch(EndPoints.resetPasswordEmailRequest, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    });

    const json = await response.json();
    checkApiError(json);

    next(
      setResetPasswordSentSuccess({
        resetPasswordSentSuccess: 'auth.success-send-reset-password-email',
      }),
    );
  } catch (error) {
    next(setAuthError({ error: error as Error }));
  } finally {
    next(setAuthLoading({ loading: false }));
  }
};
