import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { IdModel } from 'src/types';
import { BoardState } from './board.types';

export const boardSelector = (state: RootState) => state.board;

export const initialAmountCheckedSelector = createSelector(boardSelector, (board: BoardState) => {
  return board.showInitialAmount;
});

type SelectClient = {
  id: IdModel;
  title: string;
}[];

export const clientListForSelectSelector = createSelector(boardSelector, (board: BoardState): SelectClient => {
  return board.clients.map((item) => {
    return {
      id: item.id,
      title: item.name ?? item.ice,
    };
  });
});
