import { LogoutAction, LOGOUT_ACTION_START, StartLogInAction } from '../auth.types';
import {
  FORGOT_PASSWORD_RESET_PASSWORD_REQUEST,
  LOGIN_ACTION_START,
  StartForgotPasswordResetAction,
} from './authSlice';

export const startLogInAction = (email: string, password: string): StartLogInAction => ({
  type: LOGIN_ACTION_START,
  payload: {
    email,
    password,
  },
});

export const startForgotPasswordReset = (email: string): StartForgotPasswordResetAction => ({
  type: FORGOT_PASSWORD_RESET_PASSWORD_REQUEST,
  payload: {
    email,
  },
});

export const logout = (): LogoutAction => ({
  type: LOGOUT_ACTION_START,
});
