import { Action, Middleware } from 'redux';
import { resetAppSettings } from 'src/features/appSettings';
import { updateUserInfo } from 'src/features/user/store';
import { LOGOUT_ACTION_START } from '../auth.types';
import { resetAuth } from '../store/authSlice';

export const logoutMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type } = action;

  if (type !== LOGOUT_ACTION_START) {
    next(action);
    return;
  }

  next(resetAuth());
  next(updateUserInfo({ info: null }));
  next(resetAppSettings());
};
