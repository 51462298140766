import { EntriesBoardShortInfo, EntryType, OrganizationShortInfo } from 'src/types';
export const START_UPLOAD_ENTRIES_BOARD = 'board/startUpload/entries';
export const START_DELETE_ENTRY = 'board/delete/entry';
export const START_UPLOAD_FACTURE = 'board/facture/upload/entry';
export const START_VALIDATE_ENTRY = 'board/entry/validate';
export type BoardState = {
  showInitialAmount: boolean;
  fromDate: Date | null;
  toDate: Date | null;
  clientMode: boolean;
  currentClient: string | null;
  clients: OrganizationShortInfo[];
  clientsLoading: boolean;
  errorLoadingClients: Error | null;
  entries: EntriesBoardShortInfo[];
  entriesLoading: boolean;
  errorLoadingEntries: Error | null;
  uploadingEntries: boolean;
  uploadingEntriesError: Error | null;
  selectedEntriesToDelete: string[];
  factureUploadEntries: string[];
  selectedEntriesToValid: string[];
};

export type SetShownInitialAmountAction = {
  show: boolean;
};

export type SetClientModeAction = {
  clientMode: boolean;
};
export type SetToDateAction = {
  date: Date | null;
};

export type AppendEntriesAction = {
  entries: EntriesBoardShortInfo[];
};
export type SetFromDateAction = {
  date: Date | null;
};

export type EntryRawFromFile = {
  ice: string;
  date: Date;
  nbItem: string;
  type: EntryType;
  description: string;
  debit: number;
  credit: number;
};

export type SetCurrentClientAction = {
  client: string | null;
};

export type SetClientsAction = {
  clients: OrganizationShortInfo[];
};

export type setIsLoadingClientsAction = {
  isLoading: boolean;
};

export type setIsLoadingEntriesAction = {
  isLoading: boolean;
};

export type setUploadingEntriesAction = {
  isLoading: boolean;
};

export type setDeleting = {
  isLoading: boolean;
};

export type setLoadingClientErrorAction = {
  error: Error | null;
};

export type setLoadingEntriesErrorAction = {
  error: Error | null;
};

export type setEntriesAction = {
  entries: EntriesBoardShortInfo[];
};

export type setUploadingEntriesErrorAction = {
  error: Error | null;
};

export type AddDeletingEntryAction = {
  id: string;
};

export type RemoveDeletingEntryAction = {
  id: string;
};

export type DeleteEntryOnEntriesTableAction = {
  id: string;
};

export type StartUploadingEntriesAction = {
  entries: EntryRawFromFile[];
  type: typeof START_UPLOAD_ENTRIES_BOARD;
};

export type StartValidateEntryAction = {
  id: string;
  type: typeof START_VALIDATE_ENTRY;
};

export type StartDeleteEntryAction = {
  id: string;
  type: typeof START_DELETE_ENTRY;
};

export type StartUploadFactureAction = {
  file: File;
  id: string;
  type: typeof START_UPLOAD_FACTURE;
};

export type AddEntryToFactureUploadAction = {
  id: string;
};

export type RemoveEntryToFactureUploadAction = {
  id: string;
};

export type AddEntryToValidateEntriesAction = {
  id: string;
};

export type RemoveEntryToValidateEntriesAction = {
  id: string;
};

export type UpdateEntryDocumentAction = {
  id: string;
  document: string;
};

export type UpdateEntryStatusToValidateAction = {
  id: string;
};
