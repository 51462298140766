import { useCallback } from 'react';
import { EndPoints } from 'src/config';
import { useFetcher } from 'src/hooks';
import { SignUpInput, SignUpResponse } from '../signup.types';

export const useSubmitSignUp = () => {
  const { isLoading, error, data, fetcher } = useFetcher<SignUpResponse | null>(null);

  const submitSignUpRequest = useCallback(
    (payload: SignUpInput) => {
      fetcher(EndPoints.signUp, {
        requiredToken: false,
        body: {
          ...payload,
        },
      });
    },
    [fetcher],
  );

  return {
    submitSignUpRequest,
    isLoading,
    error,
    data,
  };
};
