import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextError } from '../TextError';

type Props = {
  message: string;
  testID?: string;
  className?: string;
};

export const TranslatedTextError = ({ className, message}: Props) => {
  const { t } = useTranslation();
  return <TextError message={t(message)} className={className} />;
};
