import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdminUserSelector,
  isClientEditorSelector,
  isClientViewerSelector,
  isProviderEditorSelector,
  isProviderViewerSelector,
  isSuperAdminUserSelector,
} from 'src/features/user/store';
import { boardSelector } from '../store';
import { setClientMode } from '../store/boardSlice';

export const useShowUploadExcel = () => {
  const [showToggleMode, setShowTogglerMode] = useState<boolean>(false);
  const [showUploadExcel, setShowUploadExcel] = useState<boolean>(false);
  const board = useSelector(boardSelector);
  const isClientViewer = useSelector(isClientViewerSelector);
  const isClientEditor = useSelector(isClientEditorSelector);
  const isProviderViewer = useSelector(isProviderViewerSelector);
  const isProviderEditor = useSelector(isProviderEditorSelector);
  const isAdmin = useSelector(isAdminUserSelector);
  const isSupperAdmin = useSelector(isSuperAdminUserSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdmin || isSupperAdmin) {
      setShowTogglerMode(true);
      if (!board.clientMode) {
        setShowUploadExcel(true);
        return;
      } else {
        setShowUploadExcel(false);
        return;
      }
    }

    if ((isClientViewer || isClientEditor) && (isProviderEditor || isProviderViewer)) {
      setShowTogglerMode(true);
    }

    if (isProviderEditor) {
      setShowUploadExcel(true);
    }

    if (isProviderEditor && (isClientEditor || isClientViewer) && board.clientMode) {
      setShowUploadExcel(false);
    }
  }, [
    isClientEditor,
    isClientViewer,
    isProviderEditor,
    isProviderViewer,
    board.clientMode,
    setShowTogglerMode,
    setShowUploadExcel,
    isAdmin,
    isSupperAdmin,
  ]);

  useEffect(() => {
    if (isAdmin || isSupperAdmin) {
      return;
    }

    if (isClientEditor || isClientViewer) {
      if (!isProviderEditor && !isProviderViewer) {
        dispatch(setClientMode({ clientMode: true }));
      }
    }
  }, [isClientEditor, isClientViewer, isProviderEditor, isProviderViewer, isAdmin, isSupperAdmin, dispatch]);

  return {
    showToggleMode,
    showUploadExcel,
    isClientEditor,
    adminLevel: isSupperAdmin || isAdmin,
    isProviderEditor,
    isClientViewer,
    isProviderViewer,
  };
};
