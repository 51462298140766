import { useState } from 'react';
import axios from 'axios';
import { EndPoints } from 'src/config';
import { IdModel } from 'src/types';

type UserPassWordUpdatedSuccess = {
  id:IdModel
}

export const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState<UserPassWordUpdatedSuccess| null>(null);

  const sendReset = async (password: string, token: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        EndPoints.updateUserPassword,
        { password, token },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setSuccess(response.data as unknown as UserPassWordUpdatedSuccess);
    } catch (e) {
      console.error(e);
      setError(new Error('auth.reset-password-error'));
    }

    setLoading(false);
  };

  return {
    loading,
    resetError: error,
    success,
    sendReset,
  };
};
