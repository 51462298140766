export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3300';
export const INVOICE_SERVER = process.env.REACT_APP_INVOICE_SERVER ?? 'http://localhost:4610';
export const IDLE_TIMEOUT_IN_MS = process.env.REACT_APP_IDLE_TIMEOUT_IN_MS
  ? Number(process.env.REACT_APP_IDLE_TIMEOUT_IN_MS)
  : 900000;

console.log('@ IDLE_TIMEOUT_IN_MS', IDLE_TIMEOUT_IN_MS);
export const API_VERSION = 'v1';
export const PRINTS_SOCKET_PATH = '/printers';

const buildAuthRoute = (route: string, version = API_VERSION) => {
  return `${API_BASE_URL}/api/${version}/auth/${route}`;
};

const buildApiRoute = (route: string, version = API_VERSION) => {
  return `${API_BASE_URL}/api/${version}/${route}`;
};

export const EndPoints = Object.freeze({
  updateUserPassword: buildAuthRoute('password/update'),
  login: buildAuthRoute('login'),
  resetPasswordEmailRequest: buildAuthRoute('emails/reset-password'),
  userInfo: buildApiRoute('me'),
  appConfigs: buildApiRoute('appConfigs'),
  signUp: buildApiRoute('public/signUp'),
  clients: buildApiRoute('clients'),
  entries: buildApiRoute('entries'),
  uploadEntries: buildApiRoute('uploadEntries'),
  deleteEntry: buildApiRoute('deleteEntry'),
  uploadFacture: buildApiRoute('uploadFacture'),
  validateEntry: buildApiRoute('validateEntry'),
});
