import { useTranslation } from 'react-i18next';

export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
} as const;

type BtnType = keyof typeof ButtonType;

export type Props = {
  label: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  theme?: BtnType;
  disabled?: boolean;
  extraClasses?: string;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  noBg?: boolean;
};

const getBackgroundByType = (type: BtnType): string => {
  switch (type) {
    case 'danger':
      return 'bg-red-600';
    case 'primary':
      return 'bg-primary';
    case 'secondary':
      return 'bg-dark';
    default:
      return 'bg-dark';
  }
};

export const Button = ({ noBg, onClick, style, label, theme = 'secondary', extraClasses, disabled, type }: Props) => {
  const { t } = useTranslation();
  const translated = t(label);

  const bgClass = noBg ? '' : getBackgroundByType(theme);

  return (
    <button
      className={`${extraClasses} ${bgClass} text-white font-normal text-center px-1 py-2 rounded disabled:opacity-60 active:opacity-100 md:hover:opacity-80 disabled:cursor-not-allowed`}
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={style}
    >
      {translated}
    </button>
  );
};
