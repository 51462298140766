import { isExpiredDate } from 'src/helpers/auth';
import { RootState } from 'src/store';
import { AuthState } from '../auth.types';

export const authSelector = (state: RootState): AuthState => state.auth;
export const authIsLoadingSelector = (state: RootState): boolean => state.auth.isLoading;
export const authErrorSelector = (state: RootState): Error | null => state.auth.error;
export const tokenSelector = (state: RootState): string | null => state.auth.token;

export const authForgotPasswordResetRequestSuccessSelector = (state: RootState): string | null =>
  state.auth.resetPasswordSentSuccess;

export const isAuthenticatedSelector = (state: RootState): boolean => {
  const { token, expiresIn } = state.auth;
  if (!token || !expiresIn) return false;
  return !isExpiredDate(expiresIn);
};
