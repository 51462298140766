import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { errorsInput } from 'src/consts';

export const useFormErrorParser = (error: FieldError | undefined): string | undefined => {
  const { t } = useTranslation();
  if (!error) {
    return;
  }

  switch (error.type) {
    case 'required': {
      return t(errorsInput.required);
    }
    default:
      return error.message;
  }
};
