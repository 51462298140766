import { Action, Middleware } from 'redux';
import { EndPoints } from 'src/config';
import { isAuthenticatedSelector, resetAuth, tokenSelector } from 'src/features/auth';
import { checkApiError } from 'src/handlers';
import { appHeaders } from 'src/helpers';
import { RootState } from 'src/store';
import { StartUploadFactureAction, START_UPLOAD_FACTURE } from '../board.types';
import { removeEntryFromFactureList, updateEntryDocument } from '../boardSlice';

export const uploadFactureEntryBoardMiddleware: Middleware = (api) => (next) => async (action: Action) => {
  const { type, file, id: entryId } = action as StartUploadFactureAction;

  if (type !== START_UPLOAD_FACTURE) {
    next(action);
    return;
  }

  const state: RootState = api.getState();

  const isAuthenticated = isAuthenticatedSelector(state);
  const token = tokenSelector(state);

  if (!isAuthenticated || !token) {
    next(resetAuth());
    return;
  }

  try {
    const data = new FormData();
    data.append('file', file);
    data.append('entryId', entryId);
    const response = await fetch(EndPoints.uploadFacture, {
      method: 'POST',
      headers: appHeaders(token, false),
      body: data,
    });

    const json = await response.json();
    checkApiError(json);
    api.dispatch(updateEntryDocument({ id: json.entryId, document: json.document }));
  } catch (e) {
    console.error('Error while upload facture', e);
  } finally {
    api.dispatch(removeEntryFromFactureList({ id: entryId }));
  }
};
