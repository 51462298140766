import settings from './settings.json';

import { Settings } from './settings.types';
import { useMemo } from 'react';
export * from './settings.types';

/**
 * N.B. when settings are read asynchronously, only this file will be updated
 */
export function useSettings(): Settings {
  return useMemo(() => settings, []);
}

export default useSettings;
